<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>웨딩 컬렉션 관리</h2>
    </div>
    <div class="box one">
      <div class="inputBox">
        <div class="flexL">
          <p class="bold">
            카테고리
            <span>*</span>
          </p>
          <select class="searchCondition" v-model="category" @change="reset">
            <option value disabled>선택</option>
            <option value="STUDIO">사진</option>
            <option value="DRESS">드레스/예복</option>
            <option value="MAKEUP">헤어&#38;메이크업/부케</option>
            <option value="HONEYMOON">허니문</option>
            <option value="VIDEO">웨딩영상</option>
            <option value="EVENT">주례&#38;사회/이벤트</option>
            <option value="GIFT">예물</option>
            <option value="HANBOK">한복/예단</option>
            <option value="PYEBAEK">폐백&#38;이바지/청첩장</option>
            <option value="BEAUTY">뷰티케어</option>
            <option value="INTERIOR">가전/가구/인테리어</option>
            <option value="CATERING">케이터링</option>
          </select>
        </div>
        <div class="flexL"></div>
        <div class="flexL">
          <p class="bold">상단라벨</p>
          <el-select
            v-model="topLabel"
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="미선택" label="미선택" value=""></el-option>
            <el-option
              key="스탬프투어"
              label="스탬프투어"
              value="스탬프투어"
            ></el-option>
            <el-option
              key="단독제휴"
              label="단독제휴"
              value="단독제휴"
            ></el-option>
            <el-option
              key="플래너PICK"
              label="플래너PICK"
              value="플래너PICK"
            ></el-option>
            <el-option key="기획전" label="기획전" value="기획전"></el-option>
            <el-option
              key="타임세일"
              label="타임세일"
              value="타임세일"
            ></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">이벤트라벨</p>
          <el-select v-model="label" placeholder="선택" class="searchCondition">
            <el-option key="미선택" label="미선택" value=""></el-option>
            <el-option key="EVENT" label="EVENT" value="EVENT"></el-option>
            <el-option key="HOT" label="HOT" value="HOT"></el-option>
            <el-option key="NEW" label="NEW" value="NEW"></el-option>
            <el-option key="BEST" label="BEST" value="BEST"></el-option>
            <el-option key="COUPON" label="COUPON" value="COUPON"></el-option>
            <el-option key="추천" label="추천" value="추천"></el-option>
            <el-option
              key="후기이벤트"
              label="후기이벤트"
              value="후기이벤트"
            ></el-option>
          </el-select>
        </div>

        <div class="flexL">
          <p class="bold">가격</p>
          <input type="text" v-model="price" />
        </div>

        <div class="flexL">
          <p class="bold">할인율(%)</p>
          <input type="number" v-model="discount" min="0" max="100" />
        </div>
        <div class="flexL">
          <p class="bold">
            브랜드명
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div>
        <div class="flexL">
          <p class="bold">주소</p>
          <input type="text" v-model="address" />
        </div>
        <div class="flexL">
          <p class="bold">주차</p>
          <input type="text" v-model="parking" />
        </div>
        <div class="flexL">
          <p class="bold">투어 쿠폰</p>
          <input
            type="text"
            v-model="coupons"
            placeholder="(쿠폰종류별로 ',' 로 구분해주세요. 예)1.상품권, 2.영화표)"
          />
        </div>
        <!-- 사진(웨딩촬영 + 본식스냅) -->
        <!-- 사진(웨딩촬영 + 본식스냅) -->
        <!-- 사진(웨딩촬영 + 본식스냅) -->
        <div class="flexL" v-if="category == 'STUDIO'">
          <p class="bold">
            상품 종류
            <span>*</span>
          </p>
          <el-select
            v-model="studioType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="웨딩촬영" label="웨딩촬영" value="웨딩촬영">
            </el-option>
            <el-option key="세미촬영" label="세미촬영" value="세미촬영">
            </el-option>
            <el-option key="본식스냅" label="본식스냅" value="본식스냅">
            </el-option>
            <el-option key="가봉스냅" label="가봉스냅" value="가봉스냅">
            </el-option>
            <el-option key="데이트스냅" label="데이트스냅" value="데이트스냅">
            </el-option>
            <el-option key="기타사진" label="기타사진" value="기타사진">
            </el-option>
          </el-select>
        </div>
        <div class="flexL" v-if="category == 'STUDIO'">
          <p class="bold">
            촬영자 수
            <span>*</span>
          </p>
          <el-select
            v-model="studioCount"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="1인" label="1인" value="1인"> </el-option>
            <el-option key="2인" label="2인" value="2인"> </el-option>
          </el-select>
        </div>
        <div class="flexL" v-if="category == 'STUDIO'">
          <p class="bold">
            상품 구성
            <span>*</span>
          </p>
          <el-select
            v-model="studioComprise"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="데이터" label="데이터" value="데이터"> </el-option>
            <el-option key="인화물" label="인화물" value="인화물"> </el-option>
            <el-option key="앨범" label="앨범" value="앨범"> </el-option>
            <el-option key="액자" label="액자" value="액자"> </el-option>
            <el-option
              key="본식스냅(원판)"
              label="본식스냅(원판)"
              value="본식스냅(원판)"
            >
            </el-option>
            <el-option
              key="본식스냅(스냅)"
              label="본식스냅(스냅)"
              value="본식스냅(스냅)"
            >
            </el-option>
            <el-option
              key="본식스냅(원판+스냅)"
              label="본식스냅(원판+스냅)"
              value="본식스냅(원판+스냅)"
            >
            </el-option>
          </el-select>
        </div>
        <div class="flexL" v-if="category == 'STUDIO'">
          <p class="bold">
            스타일
            <span>*</span>
          </p>
          <el-select
            v-model="studioStyle"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="인물중심" label="인물중심" value="인물중심">
            </el-option>
            <el-option key="배경다양" label="배경다양" value="배경다양">
            </el-option>
            <el-option key="인물+배경" label="인물+배경" value="인물+배경">
            </el-option>
          </el-select>
        </div>
        <div class="flexL" v-if="category == 'STUDIO'">
          <p class="bold">
            촬영 종류
            <span>*</span>
          </p>
          <el-select
            v-model="studioScene"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="웨딩촬영" label="웨딩촬영" value="웨딩촬영">
            </el-option>
            <el-option key="본식촬영" label="본식촬영" value="본식촬영">
            </el-option>
            <el-option key="한옥촬영" label="한옥촬영" value="한옥촬영">
            </el-option>
            <el-option key="야간촬영" label="야간촬영" value="야간촬영">
            </el-option>
            <el-option key="로드촬영" label="로드촬영" value="로드촬영">
            </el-option>
            <el-option key="가든촬영" label="가든촬영" value="가든촬영">
            </el-option>
            <el-option key="흑백촬영" label="흑백촬영" value="흑백촬영">
            </el-option>
            <el-option key="한복촬영" label="한복촬영" value="한복촬영">
            </el-option>
            <el-option key="캐쥬얼촬영" label="캐쥬얼촬영" value="캐쥬얼촬영">
            </el-option>
            <el-option
              key="반려동물촬영"
              label="반려동물촬영"
              value="반려동물촬영"
            >
            </el-option>
          </el-select>
        </div>

        <!-- 드레스/예복 -->
        <!-- 드레스/예복 -->
        <!-- 드레스/예복 -->
        <div class="flexL" v-if="category == 'DRESS'">
          <p class="bold">
            상품 종류
            <span>*</span>
          </p>
          <el-select
            v-model="dressType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option
              key="본식웨딩드레스"
              label="본식웨딩드레스"
              value="본식웨딩드레스"
            >
            </el-option>
            <el-option key="세미드레스" label="세미드레스" value="세미드레스">
            </el-option>
            <el-option key="예복" label="예복" value="예복"> </el-option>
          </el-select>
        </div>
        <div class="flexL" v-if="category == 'DRESS'">
          <p class="bold">
            스타일
            <span>*</span>
          </p>
          <el-select
            v-model="dressStyle"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="심플한" label="심플한" value="심플한"> </el-option>
            <el-option key="화려한" label="화려한" value="화려한"> </el-option>
          </el-select>
        </div>
        <div class="flexL" v-if="category == 'DRESS' && dressType != '예복'">
          <p class="bold">
            드레스 강점
            <span>*</span>
          </p>
          <el-select
            v-model="dressPoint"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="실크" label="실크" value="실크"> </el-option>
            <el-option key="레이스" label="레이스" value="레이스"> </el-option>
            <el-option key="비즈" label="비즈" value="비즈"> </el-option>
            <el-option key="반팔" label="반팔" value="반팔"> </el-option>
            <el-option key="벨라인" label="벨라인" value="벨라인"> </el-option>
            <el-option
              key="머메이드라인"
              label="머메이드라인"
              value="머메이드라인"
            >
            </el-option>
            <el-option key="A라인" label="A라인" value="A라인"> </el-option>
          </el-select>
        </div>
        <div class="flexL" v-if="category == 'DRESS' && dressType != '예복'">
          <p class="bold">
            사이즈
            <span>*</span>
          </p>
          <el-select
            v-model="dressSize"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="44" label="44" value="44"> </el-option>
            <el-option key="55" label="55" value="55"> </el-option>
            <el-option key="66" label="66" value="66"> </el-option>
            <el-option key="77" label="77" value="77"> </el-option>
            <el-option key="88이상" label="88이상" value="88이상"> </el-option>
          </el-select>
        </div>
        <div class="flexL" v-if="category == 'DRESS'">
          <p class="bold">
            제작 구분
            <span>*</span>
          </p>
          <el-select
            v-model="dressMade"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="국내" label="국내" value="국내"> </el-option>
            <el-option key="수입" label="수입" value="수입"> </el-option>
            <el-option key="국내+수입" label="국내+수입" value="국내+수입">
            </el-option>
          </el-select>
        </div>

        <!-- 헤어&메이크업/부케 -->
        <!-- 헤어&메이크업/부케 -->
        <!-- 헤어&메이크업/부케 -->
        <div class="flexL" v-if="category == 'MAKEUP'">
          <p class="bold">
            상품 종류
            <span>*</span>
          </p>
          <el-select
            v-model="makeupType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option
              key="신랑,신부 헤어&메이크업"
              label="신랑,신부 헤어&메이크업"
              value="신랑, 신부 헤어&메이크업"
            >
            </el-option>
            <el-option
              key="혼주메이크업"
              label="혼주메이크업"
              value="혼주메이크업"
            >
            </el-option>
            <el-option key="부케" label="부케" value="부케"> </el-option>
          </el-select>
        </div>

        <!-- 허니문 -->
        <!-- 허니문 -->
        <!-- 허니문 -->
        <div class="flexL" v-if="category == 'HONEYMOON'">
          <p class="bold">
            상품 종류
            <span>*</span>
          </p>
          <el-select
            v-model="honeymoonType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="휴양" label="휴양" value="휴양"> </el-option>
            <el-option key="관광" label="관광" value="관광"> </el-option>
            <el-option key="휴양+관광" label="휴양+관광" value="휴양+관광">
            </el-option>
          </el-select>
        </div>

        <!-- 웨딩 영상 -->
        <!-- 웨딩 영상 -->
        <!-- 웨딩 영상 -->
        <div class="flexL" v-if="category == 'VIDEO'">
          <p class="bold">
            상품 종류
            <span>*</span>
          </p>
          <el-select
            v-model="videoType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="본식영상" label="본식영상" value="본식영상">
            </el-option>
            <el-option key="리허설영상" label="리허설영상" value="리허설영상">
            </el-option>
            <el-option key="식전영상" label="식전영상" value="식전영상">
            </el-option>
            <el-option key="식중영상" label="식중영상" value="식중영상">
            </el-option>
            <el-option key="기타" label="기타" value="기타"> </el-option>
          </el-select>
        </div>

        <!-- 주례&사회/이벤트 -->
        <!-- 주례&사회/이벤트 -->
        <!-- 주례&사회/이벤트 -->
        <div class="flexL" v-if="category == 'EVENT'">
          <p class="bold">
            상품 종류
            <span>*</span>
          </p>
          <el-select
            v-model="eventType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option
              key="주례&사회자"
              label="주례&사회자"
              value="주례&사회자"
            >
            </el-option>
            <el-option key="이벤트" label="이벤트" value="이벤트"> </el-option>
          </el-select>
        </div>

        <!-- 예물 -->
        <!-- 예물 -->
        <!-- 예물 -->
        <div class="flexL" v-if="category == 'GIFT'">
          <p class="bold">
            상품 종류
            <span>*</span>
          </p>
          <el-select
            v-model="giftType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="예물" label="예물" value="예물"> </el-option>
          </el-select>
        </div>

        <!-- 한복/예단 -->
        <!-- 한복/예단 -->
        <!-- 한복/예단 -->
        <div class="flexL" v-if="category == 'HANBOK'">
          <p class="bold">
            상품 종류
            <span>*</span>
          </p>
          <el-select
            v-model="hanbokType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="한복" label="한복" value="한복"> </el-option>
            <el-option key="예단" label="예단" value="예단"> </el-option>
          </el-select>
        </div>

        <!-- 폐백&이바지/청첩장 -->
        <!-- 폐백&이바지/청첩장 -->
        <!-- 폐백&이바지/청첩장 -->
        <div class="flexL" v-if="category == 'PYEBAEK'">
          <p class="bold">
            상품 종류
            <span>*</span>
          </p>
          <el-select
            v-model="pyebaekType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option
              key="폐백&이바지"
              label="폐백&이바지"
              value="폐백&이바지"
            >
            </el-option>
            <el-option key="청첩장" label="청첩장" value="청첩장"> </el-option>
          </el-select>
        </div>

        <!-- 뷰티케어 -->
        <!-- 뷰티케어 -->
        <!-- 뷰티케어 -->
        <div class="flexL" v-if="category == 'BEAUTY'">
          <p class="bold">
            상품 종류
            <span>*</span>
          </p>
          <el-select
            v-model="beautyType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="뷰티케어" label="뷰티케어" value="뷰티케어">
            </el-option>
          </el-select>
        </div>

        <!-- 가전/가구/인테리어 -->
        <!-- 가전/가구/인테리어 -->
        <!-- 가전/가구/인테리어 -->
        <div class="flexL" v-if="category == 'INTERIOR'">
          <p class="bold">
            상품 종류
            <span>*</span>
          </p>
          <el-select
            v-model="interiorType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="가전" label="가전" value="가전"></el-option>
            <el-option key="가구" label="가구" value="가구"> </el-option>
            <el-option key="인테리어" label="인테리어" value="인테리어">
            </el-option>
            <el-option key="기타" label="기타" value="기타"> </el-option>
          </el-select>
        </div>

        <!-- 케이터링 -->
        <!-- 케이터링 -->
        <!-- 케이터링 -->
        <div class="flexL" v-if="category == 'CATERING'">
          <p class="bold">
            상품 종류
            <span>*</span>
          </p>
          <el-select
            v-model="cateringType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="케이터링" label="케이터링" value="케이터링">
            </el-option>
          </el-select>
        </div>

        <div class="flexL double">
          <p class="bold">상세 정보</p>
          <textarea v-model="content" />
        </div>
        <div class="flexL double">
          <p class="bold">유튜브 url</p>
          <input
            type="text"
            v-model="youtube"
            placeholder="유튜브에서 공유 / 퍼가기에 iframe 소스를 입력해 주세요."
          />
        </div>
        <!-- <div v-if="editMode"> -->
        <div class="flexL">
          <p class="bold">상품 정보</p>
          <button class="register" @click="handleCollectionModal(null, null)">
            등록하기
          </button>
        </div>
        <div class="flexL">
          <p class="bold">상품 갤러리</p>
          <button class="register" @click="handleCollectionModal2(null, null)">
            등록하기
          </button>
        </div>

        <div class="flexL">
          <p class="bold">
            대표사진
            <span>*</span>
          </p>
          <div class="imgBox">
            <ul>
              <li v-if="thumbnail">
                <img :src="thumbnail" alt="업로드이미지" />
                <a
                  class="cancel"
                  @click="handleDeleteFile(thumbnail, 'thumbnail')"
                >
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
              <li v-else>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="thumbnail"
                  @change="sendFile('thumbnail')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
            </ul>
            <em class="notice"
              >대표사진은 3:2 비율로 업로드 됩니다. ex) 500 * 333</em
            >
          </div>
        </div>
        <div class="flexL">
          <p class="bold">
            상세이미지
            <!-- <span>*</span> -->
          </p>
          <div class="imgBox">
            <ul>
              <li>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="images"
                  @change="sendFile('images')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
              <li v-for="(image, i) in images" :key="i">
                <img :src="image" alt="업로드이미지" />
                <a class="cancel" @click="handleDeleteFile(image, 'images')">
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="buttonWrap">
        <router-link to="/admin/collectionmanage">목록</router-link>
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </div>
    <!-- <div class="box one">
      <h2 class="bold">상품 가격 정보 입력</h2>

      <div class="flexL">
        <p class="bold">추가상품</p>
        <div>
          <input type="text" placeholder="추가상품명" />
          <input type="number" placeholder="재고 (숫자만 입력)" />
          <input type="number" placeholder="추가상품가 (숫자만 입력)" />
          <button class="basic">목록으로 적용</button>
        </div>
      </div>
      <div class="flexL optionTable">
        <table>
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 30%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
            <col style="width: 10%" />
          </colgroup>
          <tr>
            <th>NO</th>
            <th>종류</th>
            <th>상품</th>
            <th>내용</th>
            <th>공급가</th>
            <th>판매가</th>
            <th>리베이트</th>
            <th>수정/삭제</th>
          </tr>
          <tr>
            <td>1</td>
            <td>
              <el-select placeholder="선택">
                <el-option
                  key="리허설"
                  label="리허설"
                  value="리허설"
                ></el-option>
              </el-select>
              <span></span>
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td>
              <input type="text" />
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <button class="table mr5">확인</button>
              <button class="table">삭제</button>
            </td>
          </tr>
        </table>
      </div>
    </div> -->
    <div class="box one">
      <div class="tableList">
        <!-- <div class="tableList" v-if="products.length > 0"> -->
        <h2 class="bold">상품 리스트</h2>
        <table v-if="products.length > 0">
          <colgroup>
            <col style="width: 8%" />
            <col style="width: 15%" />
            <col style="width: 27%" />
            <col style="width: 18%" />
            <col style="width: 11%" />
            <col style="width: 11%" />
            <col style="width: 10%" />
          </colgroup>
          <tr>
            <th>번호</th>
            <th>상품명</th>
            <th>내용</th>
            <th class="center">
              공급가
              <br />
              <el-radio v-model="includingVat" label="on" @change="handleVat"
                >VAT 포함</el-radio
              >
              <el-radio v-model="includingVat" label="off" @change="handleVat"
                >VAT 제외</el-radio
              >
            </th>
            <th class="right">가격</th>
            <th class="right">리베이트</th>
            <th class="center">수정/삭제</th>
          </tr>
          <tr v-for="(data, i) in products" :key="i">
            <td>{{ i + 1 }}</td>
            <td @click="handleCollectionModal(data, i)">
              <em>{{ data.title }}</em>
            </td>
            <td>
              {{
                data.title == "상품 구성"
                  ? data.content
                  : data.title == "추가 사항"
                  ? data.addition
                  : data.title == "혜택 쿠폰"
                  ? data.service
                  : data.title == "기타"
                  ? data.etc
                  : ""
              }}
            </td>
            <td class="center">
              <span v-if="data.title == '상품 구성'">
                {{
                  data.supplyPrice
                    ? numberFormat(Math.floor(data.supplyPrice * vat)) + "원"
                    : ""
                }}
              </span>
            </td>
            <td class="right">
              <span v-if="data.title == '상품 구성'">
                {{ data.price ? numberFormat(data.price) + "원" : "" }}
              </span>
            </td>
            <td class="right">
              <span v-if="data.title == '상품 구성'">
                {{ data.rebate ? numberFormat(data.rebate) + "원" : "" }}
              </span>
            </td>
            <td class="center">
              <button class="table delete" @click="itemDelete(i, 'product')">
                삭제
              </button>
            </td>
          </tr>
        </table>
        <div class="nodata" v-else>
          <p>데이터가 없습니다.</p>
        </div>
      </div>
    </div>
    <div class="box one">
      <div class="tableList">
        <!-- <div class="tableList" v-if="gallerys.length > 0"> -->
        <h2 class="bold">갤러리 리스트</h2>
        <table v-if="gallerys.length > 0">
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 80%" />
            <col style="width: 10%" />
          </colgroup>
          <tr>
            <th>번호</th>
            <th>상품명</th>
            <!-- <th class="right">가격</th> -->
            <th class="center"></th>
          </tr>
          <tr v-for="(data, i) in gallerys" :key="i">
            <td>{{ i + 1 }}</td>
            <td @click="handleCollectionModal2(data, i)">
              <em>{{ data.title }}</em>
            </td>
            <!-- <td class="right">{{ numberFormat(data.price) }}원</td> -->
            <td class="center">
              <button class="table delete" @click="itemDelete(i, 'gallery')">
                삭제
              </button>
            </td>
          </tr>
        </table>
        <div class="nodata" v-else>
          <p>데이터가 없습니다.</p>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <CollectionModal
          :select="select"
          @close="close"
          @updateData="updateData"
        />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
    <transition name="fade">
      <div class="dim" v-if="modalVisible2">
        <CollectionModal2
          :select="select"
          @close="close"
          @updateData="updateData"
        />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import { uploadFile, deleteFile, deletePostFile } from "@/api/file";
import {
  createCollection,
  fetchCollectionDetail,
  upadateCollection,
  removeCollection,
} from "@/api/admin";
import CollectionModal from "@/components/pop/CollectionModal";
import CollectionModal2 from "@/components/pop/CollectionModal2";
import { format } from "@/mixins/format";

export default {
  mixins: [format],
  components: { CollectionModal, CollectionModal2 },

  data() {
    return {
      editMode: false,
      moment: moment,
      modalVisible: false,
      modalVisible2: false,
      collectionVisible: false,
      collectionVisible2: false,
      title: "",
      category: "",
      region: [],
      price: "",
      discount: "",
      content: "",
      // agency: "",
      youtube: "",
      thumbnail: "",
      parking: "",
      coupons: "",
      address: "",
      file: "",
      images: [],
      collectionId: "",
      select: null,
      index: null,
      products: [],
      gallerys: [],
      type: "",
      studioType: [],
      studioCount: [],
      studioComprise: [],
      studioStyle: [],
      studioScene: [],
      dressType: [],
      dressStyle: [],
      dressPoint: [],
      dressSize: [],
      dressMade: [],
      makeupType: [],
      honeymoonType: [],
      videoType: [],
      eventType: [],
      giftType: [],
      hanbokType: [],
      pyebaekType: [],
      beautyType: [],
      interiorType: [],
      cateringType: [],
      vat: 1.1,
      includingVat: "on",
      topLabel: "",
      label: "",
    };
  },
  watch: {
    price: function (newValue) {
      const result = newValue
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.price = result;
    },
  },

  created() {
    this.$store.dispatch("SET_NAVBAR", "3");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.collectionId = this.$route.query.id;
      this.getCollection();
    }
  },
  methods: {
    handleVat(e) {
      if (e == "on") {
        this.vat = 1.1;
      } else {
        this.vat = 1;
      }
    },
    reset() {
      this.studioType = [];
      this.studioCount = [];
      this.studioComprise = [];
      this.studioStyle = [];
      this.studioScene = [];
      this.dressType = [];
      this.dressStyle = [];
      this.dressPoint = [];
      this.dressSize = [];
      this.dressMade = [];
      this.makeupType = [];
      this.honeymoonType = [];
      this.videoType = [];
      this.eventType = [];
      this.giftType = [];
      this.hanbokType = [];
      this.pyebaekType = [];
      this.beautyType = [];
      this.interiorType = [];
      this.cateringType = [];
    },
    handleCollectionModal(data, index) {
      if (data != null && index != null) {
        this.select = data;
        this.index = index;
      }
      this.type = "product";
      this.modalVisible = true;
      this.collectionVisible = true;
    },
    handleCollectionModal2(data, index) {
      if (data != null && index != null) {
        this.select = data;
        this.index = index;
      }
      this.type = "gallery";
      this.modalVisible2 = true;
      this.collectionVisible2 = true;
    },
    itemDelete(index, type) {
      if (type == "product") {
        this.products.splice(index, 1);
      } else if (type == "gallery") {
        this.gallerys.splice(index, 1);
      }
    },
    close() {
      this.index = null;
      this.select = null;
      this.modalVisible = false;
      this.collectionVisible = false;
      this.modalVisible2 = false;
      this.collectionVisible2 = false;
    },
    updateData(data) {
      if (this.type == "product") {
        if (this.index != null) {
          this.products.splice(this.index, 1, data);
        } else {
          this.products.push(data);
        }
      } else if (this.type == "gallery") {
        if (this.index != null) {
          this.gallerys.splice(this.index, 1, data);
        } else {
          this.gallerys.push(data);
        }
      }
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    getCollection() {
      fetchCollectionDetail(this.collectionId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.category = res.data.data.category;
          this.price = res.data.data.price;
          this.discount = res.data.data.discount;
          this.content = res.data.data.content;
          this.images = res.data.data.images;
          this.thumbnail = res.data.data.thumbnail;
          this.youtube = res.data.data.youtube;
          this.createdAt = res.data.data.createdAt;
          this.products = res.data.data.products;
          this.gallerys = res.data.data.gallerys;
          this.parking = res.data.data.parking;
          this.coupons = res.data.data.coupons;
          this.address = res.data.data.address;

          this.studioType = res.data.data.studioType;
          this.studioCount = res.data.data.studioCount;
          this.studioComprise = res.data.data.studioComprise;
          this.studioStyle = res.data.data.studioStyle;
          this.studioScene = res.data.data.studioScene;
          this.dressType = res.data.data.dressType;
          this.dressStyle = res.data.data.dressStyle;
          this.dressPoint = res.data.data.dressPoint;
          this.dressSize = res.data.data.dressSize;
          this.dressMade = res.data.data.dressMade;
          this.makeupType = res.data.data.makeupType;
          this.honeymoonType = res.data.data.honeymoonType;
          this.videoType = res.data.data.videoType;
          this.eventType = res.data.data.eventType;
          this.giftType = res.data.data.giftType;
          this.hanbokType = res.data.data.hanbokType;
          this.pyebaekType = res.data.data.pyebaekType;
          this.beautyType = res.data.data.beautyType;
          this.interiorType = res.data.data.interiorType;
          this.cateringType = res.data.data.cateringType;

          this.topLabel = res.data.data.topLabel;
          this.label = res.data.data.label;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    sendFile(type) {
      if (type == "thumbnail") {
        if (this.thumbnail) {
          this.$alert("썸네일 삭제 후 업로드 해주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
        this.file = this.$refs.thumbnail.files[0];
      } else if (type == "images") {
        this.file = this.$refs.images.files[0];
      }
      if (this.file == undefined) {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            if (type == "thumbnail") {
              this.thumbnail = res.data.url;
            } else if (type == "images") {
              this.images.push(res.data.url);
            }
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    handleDeleteFile(url, type) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        if (this.collectionId) {
          deletePostFile(this.collectionId, url, type).then((res) => {
            if (res.data.status == 200) {
              if (type == "thumbnail") {
                this.thumbnail = "";
              } else if (type == "images") {
                this.images.splice(this.images.indexOf(url), 1);
              }
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        } else {
          deleteFile(url).then((res) => {
            if (res.data.status == 200) {
              if (type == "thumbnail") {
                this.thumbnail = "";
              } else if (type == "images") {
                this.images.splice(this.images.indexOf(url), 1);
              }
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        }
      });
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removeCollection(this.collectionId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "웨딩 컬렉션 정보가 삭제 되었습니다.",
              type: "success",
            });
            this.$router.replace("/admin/collectionmanage");
          }
        });
      });
    },

    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    submit() {
      if (this.category == "") {
        this.openAlert("카테고리를 선택해 주세요.");
        return;
      } else if (this.title == "") {
        this.openAlert("브랜드명을 입력해 주세요.");
        return;
      }
      // else if (this.agency == "") {
      //   this.openAlert("브랜드명를 입력해 주세요.");
      //   return;
      // }
      else if (this.category == "STUDIO" && this.studioType.length == 0) {
        this.openAlert("상품 종류를 선택해 주세요.");
        return;
      } else if (this.category == "STUDIO" && this.studioCount.length == 0) {
        this.openAlert("촬영자 수를 선택해 주세요.");
        return;
      } else if (this.category == "STUDIO" && this.studioComprise.length == 0) {
        this.openAlert("상품 구성을 선택해 주세요.");
        return;
      } else if (this.category == "STUDIO" && this.studioStyle.length == 0) {
        this.openAlert("스타일을 선택해 주세요.");
        return;
      } else if (this.category == "STUDIO" && this.studioScene.length == 0) {
        this.openAlert("촬영 종류를 선택해 주세요.");
        return;
      } else if (this.category == "DRESS" && this.dressType.length == 0) {
        this.openAlert("상품 종류를 선택해 주세요.");
        return;
      } else if (this.category == "DRESS" && this.dressStyle.length == 0) {
        this.openAlert("스타일을 선택해 주세요.");
        return;
      } else if (
        this.category == "DRESS" &&
        this.dressType != "예복" &&
        this.dressPoint.length == 0
      ) {
        this.openAlert("드레스 강점을 선택해 주세요.");
        return;
      } else if (
        this.category == "DRESS" &&
        this.dressType != "예복" &&
        this.dressSize.length == 0
      ) {
        this.openAlert("사이즈를 선택해 주세요.");
        return;
      } else if (this.category == "DRESS" && this.dressMade.length == 0) {
        this.openAlert("제작 구분을 선택해 주세요.");
        return;
      } else if (this.category == "MAKEUP" && this.makeupType.length == 0) {
        this.openAlert("상품 종류를 선택해 주세요.");
        return;
      } else if (
        this.category == "HONEYMOON" &&
        this.honeymoonType.length == 0
      ) {
        this.openAlert("상품 종류를 선택해 주세요.");
        return;
      } else if (this.category == "VIDEO" && this.videoType.length == 0) {
        this.openAlert("상품 종류를 선택해 주세요.");
        return;
      } else if (this.category == "EVENT" && this.eventType.length == 0) {
        this.openAlert("상품 종류를 선택해 주세요.");
        return;
      } else if (this.category == "GIFT" && this.giftType.length == 0) {
        this.openAlert("상품 종류를 선택해 주세요.");
        return;
      } else if (this.category == "HANBOK" && this.hanbokType.length == 0) {
        this.openAlert("상품 종류를 선택해 주세요.");
        return;
      } else if (this.category == "PYEBAEK" && this.pyebaekType.length == 0) {
        this.openAlert("상품 종류를 선택해 주세요.");
        return;
      } else if (this.category == "BEAUTY" && this.beautyType.length == 0) {
        this.openAlert("상품 종류를 선택해 주세요.");
        return;
      } else if (this.category == "INTERIOR" && this.interiorType.length == 0) {
        this.openAlert("상품 종류를 선택해 주세요.");
        return;
      } else if (this.category == "CATERING" && this.cateringType.length == 0) {
        this.openAlert("상품 종류를 선택해 주세요.");
        return;
        // } else if (this.content == "") {
        //   this.openAlert("상세 정보를 입력해 주세요.");
        //   return;
        // } else if (this.price == "") {
        //   this.$alert("가격을 입력해 주세요.", {
        //     confirmButtonText: "확인",
        //     center: true,
        //   });
        //   return;
        // } else if (this.discount == "") {
        //   this.$alert("할인율을 입력해 주세요.", {
        //     confirmButtonText: "확인",
        //     center: true,
        //   });
        //   return;
        // } else if (this.thumbnail == "") {
        //   this.$alert("대표사진을 등록해 주세요.", {
        //   confirmButtonText: "확인",
        //   center: true,
        // });
        // return;
        // } else if (this.images == "") {
        //   this.$alert("상품이미지를 등록해 주세요.", {
        //   confirmButtonText: "확인",
        //   center: true,
        // });
        // return;
      }

      let data = {
        collectionId: this.collectionId,
        title: this.title,
        category: this.category,
        // agency: this.agency,
        price: this.uncomma(this.price),

        content: this.content,
        discount: this.discount,
        images: this.images,
        thumbnail: this.thumbnail,
        youtube: this.youtube,
        products: this.products,
        gallerys: this.gallerys,
        parking: this.parking,
        coupons: this.coupons,
        address: this.address,
        studioType: this.studioType,
        studioCount: this.studioCount,
        studioComprise: this.studioComprise,
        studioStyle: this.studioStyle,
        studioScene: this.studioScene,
        dressType: this.dressType,
        dressStyle: this.dressStyle,
        dressPoint: this.dressPoint,
        dressSize: this.dressSize,
        dressMade: this.dressMade,
        makeupType: this.makeupType,
        honeymoonType: this.honeymoonType,
        videoType: this.videoType,
        eventType: this.eventType,
        giftType: this.giftType,
        hanbokType: this.hanbokType,
        pyebaekType: this.pyebaekType,
        beautyType: this.beautyType,
        interiorType: this.interiorType,
        cateringType: this.cateringType,

        topLabel: this.topLabel,
        label: this.label,
      };

      if (this.editMode) {
        upadateCollection(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("웨딩 컬렉션 정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/collectionmanage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createCollection(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("웨딩 컬렉션이 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/collectionmanage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
  },
};
</script>
